<template>
    <div class="form-group mt-3 p-3 border-light border p-2 bg-white rounded-lg">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10">
                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>E-Talks</b></h2></div>
            </div>
            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                <router-link :to="'/admin/courses/'+this.paramsId+'/conferences/create'"  class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-0 border-0 text-uppercase "><i style="margin-right: 5px;" class="fas fa-plus"></i> E-Talks</router-link>
            </div>
        </div>
        <hr>
        <div class="row justify-content-center" v-if="conference.length && !isLoad">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                <div class="card w-100 p-3 bg-grey p-lg-2 border-0 mb-3 rounded-lg d-block float-left" v-for="(item, index) in conference" :key="index">
                    <div class="row mb-0">    
                        <p class="float-left text-grey-900 fw-700 mt-2 ml-3 d-flex col" style="height: 10%;">{{item?.zoom_id?.topic | truncate(30, '...')}}</p>    
                        <button class="btn float-right mt-1 mr-2" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather-settings d-inline-block font-xl text-current mr-3"></i>
                            <div class="dropdown-menu">
                                <button @click="deleteAlert(item.id)" class="dropdown-item"><i class="fas mr-2 fa-trash"></i>Delete</button>
                            </div>
                        </button>
                    </div>
                    <div class="row justify-content-around ml-1">
                        <div class="col float-left text-left my-2">
                            <span class="font-xssss fw-700 text-grey-600 d-block">Agenda : {{item?.zoom_id?.agenda | truncate(30, '...')}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-around ml-1">
                        <div class="col float-left text-left my-2">
                            <span class="font-xssss fw-600 text-grey-500 d-block">Date & Time : </span>
                            <small class="">{{item?.zoom_id?.start_time | formatDate}}</small>
                        </div>
                        <div class="col float-right text-right my-2">
                            <a target="_blank" :href="item?.zoom_id?.start_url" class="btn btn-current">Start Conference</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 my-4">
                <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                    <li v-for="(link, index) in announcements.links" :key="index" class="page-item m-1">
                        <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                        <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                        <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeCourse(link.label)">{{link.label}}</button>
                    </li>
                </ul>
            </div> -->
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                <div class="my-3" v-show="isLoad">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
                <img :src="'/images/empty.png'" v-show="!conference.length && !isLoad" alt="" width="300">
                <h1 class="text-muted" v-show="!conference.length && !isLoad">Data don't exist</h1>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            conference: {},
            paramsId: this.$route.params.idCourse,
            isLoad: true
        }
    },
    created(){
        this.getConference()
    },
    methods:{
        async getConference(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/conference?slug_course=${this.paramsId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.conference = res.data
            }).catch(() => {
                this.isLoad = false
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteConference(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Succcess',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteConference(id){
            var data = {
                conference_id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/conference`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.isLoad = true
                this.getConference()
                return res
            }).catch(err => {
                return err
            })
        },
    }
}
</script>